import React from "react";
import { useEffect } from "react";
import mountain from "../../Images/mountain3.jpg";
import logo from "../../Images/sideLogoWhite2.svg";
import HeaderMenu from "../../Components/HeaderMenu";
import FooterWebsite from "../../Components/FooterWebsite";

export default function TempLandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <HeaderMenu /> */}
      <main className="h-screen">
        <img
          src={mountain}
          alt=""
          className="absolute inset-0 -z-10 h-full w-full  object-top"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
          <div className="py-4 w-1/2 mx-auto bg-black/40 rounded-xl my-4">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
              Coming Soon!
            </h1>
            <p className="mt-4 text-base text-white sm:mt-6">
              We're working hard to bring you something amazing. Stay tuned!
            </p>
            <div className="mt-10 flex justify-center">
              <img src={logo} alt="" className="w-1/2" />
            </div>
          </div>
        </div>
      </main>
      {/* <FooterWebsite /> */}
    </div>
  );
}
