import React from "react";
import "./App.css";
import LandingPage from "./Pages/LandingPage/LandingPage";
import TempLandingPage from "./Pages/LandingPage/tempLandingPage";

function App() {
  return (
    <div id='App' name='App' className='App'>
      <TempLandingPage />
    </div>
  );
}

export default App;
